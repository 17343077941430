.wysiwyg {
    p, ul, ol, li, a, h2, h3, i, em, b, strong {
        &:empty {
            display: none;
        }
    }

    p, ul, ol {
        margin-bottom: 1.25em;
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        padding-left: 1.25em;
        list-style: disc;
        li {
            display: list-item;
        }
    }

    ol {
        list-style: none;
        counter-reset: ol-counter;
        li {
            counter-increment: ol-counter;
            display: flex;
            &:before {
                content: counter(ol-counter) ". ";
                font-weight: normal;
                margin-right: 0.4em;
                margin-left: -0.2em;
            }
        }
    }

    li {
        margin-bottom: 0.25em;
    }

    h2, h3 {
        font-weight: 400;
        margin-bottom: 0.25em;
    }

    i, em {
        font-style: italic;
    }

    b, strong {
        font-weight: 400;
    }
}
