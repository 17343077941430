.person-plug {
    body.bg-red-lightest & {
        background: #FFF;
        color: #000;
    }

    &__image {
        @include from(sp) {
            max-width: 175px;
        }
        @include from(m) {
            max-width: 250px;
        }
        @include from(l) {
            max-width: 290px;
        }
    }
}
