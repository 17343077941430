/**
========================
Custom Tailwind components
https://tailwindcss.com/docs/extracting-components
========================
 */

@layer components {
    /** TYPO **/
    .h1 {
        @apply text-32 l:text-48 leading-42 l:leading-57;
    }

    .h2 {
        @apply text-32 leading-42 l:text-40 l:leading-52;
    }

    .h3 {
        @apply text-28 l:leading-40;
    }

    .h4 {
        @apply text-22 leading-32 l:text-24 l:leading-34;
    }

    .h5 {
        @apply font-normal text-20 l:text-24 leading-26 l:leading-32;
    }

    .sticky-left-colum__heading {
        font-size: 30px;
        line-height: 1.1;
        @include from(m) {
            font-size: clamp(26px, 2.4vw, 32px);
        }
        @include from(l) {
            font-size: clamp(32px, 2.3vw, 40px);
        }
    }

    .margins {
        @apply mb-40 m:mb-64 l:mb-80;
        &--less {
            @apply mb-20 m:mb-32 l:mb-40;
        }
        &--minor {
            @apply mb-12 m:mb-24 l:mb-32;
        }
        &--extra {
            @apply mb-72 m:mb-108 l:mb-144;
        }
    }

    .padding {
        @apply py-40 m:py-64 l:py-80;
    }

    .logo-payoff {
        @include until(m) {
            max-width: 24ch;
        }
    }

    .frontpage-menu-button {
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: all 0.3s;
        svg {
            transition: transform 0.3s ease-out;
        }
        &:hover {
            text-decoration-color: currentColor;
            svg {
                transform: translateX(5px);
            }
        }
    }

    .caption {
        @apply text-16 leading-21;
    }

    .btn-base {
        @apply font-normal whitespace-no-wrap uppercase text-12 leading-21 pt-6 pb-4 px-16 rounded-40px transition duration-300 ease-in-out;
    }

    .tag {
        @apply btn-base bg-white text-inherit hover:bg-opacity-85;
    }

    .button, .button--inverted {
        @apply inline-flex items-center btn-base;
        svg {
            @apply hidden;
        }
        &[aria-pressed="true"] {
            @apply bg-purple-darkest text-white;
            svg {
                display: block;
                width: 11px;
                height: auto;
                margin-top: -2px;
                margin-left: 7px;
                path {
                    stroke-width: 2px;
                }
            }
        }
    }

    .button {
        @apply bg-green-lightest text-black hover:text-green-darkest hover:bg-green-lighter;
        &--inverted {
            @apply bg-white text-green-darkest hover:text-black hover:bg-opacity-50;
        }
    }



    .play-button {
        a:hover & {
            @apply bg-black text-white transition-colors duration-300 ease-in-out;
        }
    }
    .plus-button {
        width: 32px;
        height: 32px;
        top: 8px;
        right: 8px;
        .flickity-enabled.is-fullscreen & {
            display: none;
        }
        &:hover {
            @apply bg-black text-white transition-colors duration-300 ease-in-out;
        }
    }
    .prevnext {
        @apply inline-flex bg-purple-lightest text-black justify-between;

        &.-inverted {
            @apply bg-purple-darkest text-purple-lightest;
        }

        width: 66px;
        height: 32px;
        padding: 3px;
        border-radius: 16px;

        @include from(m) {
            width: 92px;
            height: 40px;
            border-radius: 20px;
        }

        &__button {
            @apply flex items-center justify-center hover:bg-purple-darkest hover:text-purple-lightest transition-colors duration-300 ease-in-out;

            .-inverted & {
                @apply hover:bg-purple-lightest hover:text-purple-darkest;
            }

            &:hover svg {
                transform: translateX(0) !important;
            }

            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }

            width: 26px;
            height: 26px;
            border-radius: 50%;
            @include from(m) {
                width: 34px;
                height: 34px;
            }

            svg {
                @apply transition-transform duration-300 ease-in-out;
                width: 16px;
                height: 16px;
                transform: translateX(3px);
                &.next {
                    transform: translateX(-3px);
                }
                @include from(m) {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    .text-block {
        h2 {
            @apply text-22 leading-32 l:text-24 l:leading-34 mb-24;
        }
    }
    .video-embed {
        iframe {
            @apply placed-image;
        }
    }
}
