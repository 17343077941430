.image-zoom {
    img, video {
        transition: opacity 0.375s $easeOutQuad, transform 0.5s $easeOutQuad;
        will-change: opacity, transform;
    }
    &:hover, &:focus {
        img, video {
            transform: scale(1.025);
        }
    }
}
