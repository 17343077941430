.checkbox {
    position: relative;
    display: flex;
    flex-wrap: nowrap;

    span {
        flex: 1 1 auto;
        margin-top: -3px;
        margin-left: 8px;
        user-select: none;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        appearance: none;
        opacity: 0;
    }

    svg {
        display: block;
        flex: 0 0 auto;
        g {
            transition: opacity 0.15s ease-in-out;
            opacity: 0;
        }
    }

    input:checked + svg g {
        opacity: 1;
    }
}
