.follow {
    position: relative;
    pointer-events: auto;

    &__button {
        text-decoration: underline;
        &[aria-expanded=true] {
            visibility: hidden;
        }
        &:hover, &:focus {
            font-style: italic;
        }
        .is-success & {
            display: none;
        }
    }

    &__form {
        position: absolute;
        top: 0;
        left: 0;
        @include from(l) {
            top: -5px;
        }
        &-field {
            background: transparent;
            border: 0;
            width: calc(100% - 30px);
            font-weight: normal;
            font-size: 20px;
            line-height: normal;
            @include from(l) {
                font-size: 24px;
            }
            &::placeholder {
                font-style: italic;
                color: inherit;
                opacity: 0.45;
            }
        }

        &-checkbox {
            .is-success & {
                display: none;
            }
        }

        &-wrapper {
            .is-success & {
                display: none;
            }
        }

        &-success {
            .is-success & {
                display: block;
                margin-top: 5px;
            }
        }

        &-error {
            position: absolute;
            bottom: calc(100% - 2px);
            .is-error & {
                display: block;
            }
        }

        &-submit {
            position: absolute;
            top: 5px;
            right: 0;
            width: 24px;
            height: 24px;
        }
    }
}
