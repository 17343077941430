.sticky-left-colum {
    @include from(m) {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 0;
        overflow: visible;
        @supports (position: sticky) {
            position: sticky;
            top: 228px;
        }
    }
}
