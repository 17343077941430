.fixed-follow {
    display: none;
    @include from(m) {
        display: block;
        position: fixed;
        z-index: 3;
        top: 45vh;
        width: 100%;
        height: 0;
        pointer-events: none;
        a {
            pointer-events: auto;
        }
    }
    @include from(l) {
        top: calc(100vh - 170px);
    }
}
