.newsletter-form {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    &__wrapper {
        flex: 1 1 auto;
        width: 100%;
        max-width: 250px;
    }

    &__field {
        background: transparent;
        border: 0;
        width: 100%;

        font-weight: normal;
        font-size: 24px;
        line-height: normal;
        &::placeholder {
            color: inherit;
            opacity: 1;
        }
        &-wrapper {
            margin-bottom: 11px;
            &:after {
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                left: 0;
                bottom: 4px;
                z-index: -1;
                background: currentColor;
                pointer-events: none;
            }
        }
    }

    &__checkbox {
        .is-success &, .is-error & {
            display: none;
        }
    }

    &__success {
        .is-success & {
            display: block;
        }
    }

    &__error {
        .is-error & {
            display: block;
        }
    }

    &__submit {
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        margin-top: 4px;
        margin-left: 20px;
    }
}
