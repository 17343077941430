.page-menu {
    display: none;

    &:target {
        display: block;
    }

    &__wrapper {
        padding: 116px 0 40px;
        @include from(m) {
            padding: 160px 0 72px;
        }
        @include from(l) {
            padding: 168px 0 80px;
        }
    }

    &__some {
        margin-top: 24px;
        @include from(m) {
            margin-top: -26px;
        }
    }
}
