.page-header {
    &.headroom {
        position: fixed;
        background: inherit;
        will-change: transform;
        transition: transform 350ms ease-in-out, background 350ms ease-in-out;
    }
    &.headroom--pinned {
        transform: translateY(0%);
    }
    &.headroom--unpinned {
        transform: translateY(-100%);
    }
    &.headroom--frozen {
        background: transparent;
    }

    &__logo {
        width: 215px;
        height: 22px;
        @include from(m) {
            width: 400px;
            height: 40px;
        }
    }

    &__hamburger {
        height: 25px;
        i {
            display: block;
            width: 18px;
            height: 2px;
            background: currentColor;
            margin-bottom: 4px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
