/*

    @font-face declarations

    Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"

 */

@layer base {
    @font-face {
        font-family: 'Whyte';
        src: url('../fonts/Whyte-Light.woff2') format('woff2'), url('../fonts/Whyte-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Whyte';
        src: url('../fonts/Whyte-LightItalic.woff2') format('woff2'), url('../fonts/Whyte-LightItalic.woff') format('woff');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }
    @font-face {
        font-family: 'Whyte';
        src: url('../fonts/Whyte-Regular.woff2') format('woff2'), url('../fonts/Whyte-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Whyte';
        src: url('../fonts/Whyte-RegularItalic.woff2') format('woff2'), url('../fonts/Whyte-RegularItalic.woff') format('woff');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }
}
