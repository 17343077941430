.fixed-anchors {
    position: sticky;
    width: 100%;
    top: 228px;

    @include from(m) {
        pointer-events: none;
        height: 0;
        overflow: visible;
    }

    @include until(m) {
        position: fixed;
        top: 108px;
        z-index: 39;
        padding: 14px 0;
        &.headroom {
            will-change: transform;
            transition: transform 350ms ease-in-out;
        }
        &.headroom--pinned {
            transform: translateY(0);
        }
        &.headroom--unpinned {
            transform: translateY(-108px);
        }
    }

    &__items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        pointer-events: auto;
        @include until(m) {
            display: flex;
            flex-wrap: nowrap;
        }
    }
    &__item {
        flex: 0 0 auto;
        margin-right: 20px;
        @include until(m) {
           white-space: nowrap;
        }
        @include from(m) {
            display: block;
            margin-right: 0;
            margin-bottom: 2px;
            text-decoration: underline;
            text-decoration-color: transparent;
        }
        @include from(l) {
            margin-bottom: 6px;
        }
        &[aria-current] {
            font-weight: normal;
            text-decoration-color: currentColor;
        }
        &:not([aria-current]) {
            transition: text-decoration-color 0.25s ease-in-out;
            &:hover {
                text-decoration-color: currentColor;
            }
        }
    }
}
