.slideshow {
    &__slide {
        width: calc((100% - #{calc(gutter(min)/2)}) * 0.8 - #{calc(gutter(min)/2)});
        margin-right: gutter(min);
        flex: 1 0 auto;
        @include from(m) {
            width: calc((100% - #{calc(gutter(m)/2)}) * 0.375 - #{calc(gutter(m)/2)});
            margin-right: gutter(m);
        }
        @include from(l) {
            width: calc((100% - #{calc(gutter(l)/2)}) * 0.375 - #{calc(gutter(l)/2)});
            margin-right: gutter(l);
        }
    }
}
