.arrow-link {
    svg {
        transition: transform 0.3s $easeOutQuad;
        will-change: transform;
    }
    &:hover, &:focus {
        svg {
            transform: translateX(10px);
        }
    }

    &--left {
        &:hover, &:focus {
            svg {
                transform: translateX(-10px);
            }
        }
    }
}
