/*
==========================
Fonts
** Remember to configure font families for Tailwind in src/tailwind.config.js
==========================
 */
$fontSizeBase: 17px;
$lineHeightBase: calc(25/17);
$fontDefault: Whyte, sans-serif;

/*
==========================
Colors
** Remember to configure colors for Tailwind in src/tailwind.config.js
==========================
 */
$colorBlack: black;
$colorWhite: white;
$colorBlue: blue;
$colorRed: red;

$colorText: $colorBlack;
$colorBackground: $colorWhite;

/*
==========================
Breakpoints
** Remember to configure breakpoints for Tailwind in tailwind.config.js!**
==========================
 */

$small: 320px;
$smallPlus: 750px;
$medium: 1000px;
$mediumPlus: 1200px;
$large: 1400px;
$largePlus: 1600px;
$xlarge: 1800px;

$breakpoints: (
    s: $small,
    sp: $smallPlus,
    m: $medium,
    mp: $mediumPlus,
    l: $large,
    lp: $largePlus,
    xl: $xlarge,
);

/*
==========================
Grid settings
==========================
 */

$gutterMap: (
    min: 16px,
    s: 16px,
    sp: 16px,
    m: 26px,
    mp: 26px,
    l: 40px,
    lp: 40px,
    xl: 40px
);

$outerMarginsMap: (
    min: 18px,
    s: 18px,
    sp: 18px,
    m: 81px,
    mp: 81px,
    l: 80px,
    lp: 80px,
    xl: 80px
);

$maxContentWidth: 1600px;

// If different max widths are needed, comment this in and comment the variable above out.
//
// $maxContentWidth: (
//     min: 1280px,
//     s: 1280px,
//     sp: 1280px,
//     m: 1280px,
//     mp: 1280px,
//     l: 1280px,
//     lp: 1440px,
//     xl: 1600px
// );

$grid-breakpointMap: $breakpoints;
//$grid-breakpoints: (s, sp, m, mp, l, lp, xl);
$grid-breakpoints: (s, sp, m, l, lp);
$grid-columns: 12;
$grid-outer-margins: $outerMarginsMap;
$grid-gutters: $gutterMap;
$grid-flexbox: true;
$grid-pull-columns: 6;
$grid-push-columns: 6;
$grid-use-long-classnames: false;
$grid-use-em: false;
$grid-max-width: $maxContentWidth; //Should include support for multiple max-widths at different breakpoings. See NAF Digital
//$grid-max-width-map: $maxContentWidth; // Optional. Use if different max widths are needed at different breakpoints
