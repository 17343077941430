.ticker {
    @keyframes ticker {
        from {
            transform: translateX(0%);
        }

        to {
            transform: translateX(-100%);
        }
    }
    span {
        will-change: transform;
        animation: ticker 10s linear infinite;
    }
}
