/* ==============================
    Basic/generic global styles
 ============================== */
html, body {
    background-color: $colorBackground;
    color: $colorText;
    scroll-padding-top: 109px;
    @include from(m) {
        scroll-padding-top: 158px;
    }
}

body {
    position: relative;
    font-weight: 300;
    font-size: $fontSizeBase;
    line-height: $lineHeightBase;
    font-family: $fontDefault;
    overflow-anchor: none;
    padding-top: 109px;
    @include from(m) {
        padding-top: 228px;
    }
    @include from(l) {
        font-size: 20px;
        line-height: calc(28/20);
    }
}



.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.375s $easeOutQuad;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

.image-background {
    background: rgba(0, 0 , 0, 0.1);
}

.line-link,
.wysiwyg a {
    text-decoration: underline;
    text-decoration-color: currentColor;
    transition: text-decoration-color 0.375s $easeOutQuad;
    .using-mouse &:hover {
        text-decoration-color: transparent;
    }
}

.line-link--inverted {
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color 0.375s $easeOutQuad;
    .using-mouse &:hover {
        text-decoration-color: currentColor;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}
