.form {
    &__receipt {
        width: 100%;
        font-weight: normal;
        margin-top: 24px;
        @include from(m) {
            margin-top: 32px;
        }
        &--success {
            display: none;
            &:target {
                display: block;
            }
        }
    }
}
