@mixin grid-wrap($breakpoint: null) {
  $breakpoint-key: $breakpoint;

  .g-wrap {
    @if $breakpoint == null {
      $breakpoint-key: 'min';
      margin-left: auto;
      margin-right: auto;

      @if $grid-flexbox {
        display: flex;
        flex-wrap: wrap;

        &--no-flex {
            *zoom: 1;
            display: block;

            &:before, &:after {
              content: ""; display: table;
            }

            &:after {
              clear: both;
            }
        }
      } @else {
          *zoom: 1;
          display: block;

          &:before, &:after {
            content: ""; display: table;
          }

          &:after {
            clear: both;
          }
      }
    }

    @if $grid-max-width-map {
         @if map-has-key($grid-max-width-map, $breakpoint-key) {
            max-width: grid-get-value-from-map($breakpoint-key, $grid-max-width-map) + calc(grid-get-value-from-map($breakpoint-key, $grid-outer-margins)*2);
         }

    } @else {
        @if $grid-max-width != false {
            max-width: $grid-max-width + calc(grid-get-value-from-map($breakpoint-key, $grid-outer-margins)*2);
        }

    }

    $padding: grid-get-value-from-map($breakpoint-key, $grid-outer-margins) - calc(grid-get-value-from-map($breakpoint-key, $grid-gutters)/2);

    @if $padding > 0 {
        padding-left: $padding;
        padding-right: $padding;
    }
  }
}
