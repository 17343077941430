.form-field {
    width: 100%;
    flex: 0 1 auto;
    &--75 {
        width: calc(75% - 8px);
        @include from(m) {
            width: calc(75% - 13px);
        }
        @include from(l) {
            width: calc(75% - 20px);
        }
    }
    &--50 {
        width: calc(50% - 8px);
        @include from(m) {
            width: calc(50% - 13px);
        }
        @include from(l) {
            width: calc(50% - 20px);
        }
    }
    &--25 {
        width: calc(25% - 8px);
        @include from(m) {
            width: calc(25% - 13px);
        }
        @include from(l) {
            width: calc(25% - 20px);
        }
    }
    label {
        display: block;
        font-size: 13px;
        line-height: 1.2;
        margin-bottom: 2px;
        @include from(l) {
            font-size: 16px;
            margin-bottom: 2px;
        }
    }

    input, textarea {
        width: 100%;
        font-size: 17px;
        font-weight: 300;
        line-height: normal;
        color: #002B25;
        @include from(m) {
            font-size: 19px;
        }
        @include from(l) {
            font-size: 22px;
        }
    }

    input {
        background: transparent;
        border-bottom: 1px solid currentColor;
        padding-bottom: 0.2em;
        opacity: 0.5;
        &::placeholder {
            color: #002B25;
            opacity: 1;
        }
        &:focus {
            opacity: 1;
        }
    }

    textarea {
        margin-top: 4px;
        padding: 0.75em;
        &::placeholder {
            color: #002B25;
            opacity: 0.5;
        }
        &:focus::placeholder {
            opacity: 1;
        }
    }
}
