.calendar-widget {
    @include from(m) {
        position: sticky;
        top: 228px;
    }

    &__pagination {
        position: absolute;
        top: 0;
        right: 0;
    }
}
